// extracted by mini-css-extract-plugin
export const root = "header-module--root--2Pllp";
export const light = "header-module--light--1OSSN";
export const dark = "header-module--dark--1YreH";
export const scrollingDown = "header-module--scrollingDown--2L_Sh";
export const wrapper = "header-module--wrapper--182n5";
export const branding = "header-module--branding--1PjA2";
export const mobileMenuButton = "header-module--mobileMenuButton--1QstQ";
export const nav = "header-module--nav--106zH";
export const navLink = "header-module--navLink--1FtOH";
export const isActive = "header-module--isActive--3EQlP";
export const lightSwitch = "header-module--lightSwitch--1aNvO";
export const exhibitionLink = "header-module--exhibitionLink--32Vx4";
export const showNav = "header-module--showNav--CuuOZ";