import React from "react";
import { Link } from "gatsby";
import Header from "./header";
import ThemeContext from "../context/ThemeContext";

import Logo from "../assets/vca-make-logo.inline.svg";
import SubscribeForm from "./subscribe-form";
import "../styles/layout.css";
import * as styles from "./layout.module.css";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <ThemeContext.Consumer>
    {theme => (
      <div className={theme.dark ? 'dark' : 'light'}>
        <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
        <div className={styles.content}>{children}</div>
        <footer className={styles.footer}>
          <div className={styles.subscribeInfo}>
            <div>
              <h3>Keep in touch</h3>
            </div>
            <div>
              <SubscribeForm />
            </div>
          </div>
          <div className={styles.footerLinks}>
            <div className={styles.footerBranding}>
              <a href="https://www.makearchitects.com/" target="_blank" rel="noopener noreferrer">
                <Logo />
              </a>
            </div>
            <div>
              <ul className={styles.footerLinkList}>
                {/* <li><a href="">Contact</a></li>
                <li><a href="">Instagram</a></li> */}
                <li><Link to="/privacy-policy" activeClassName={styles.isActive}>Privacy</Link></li>
                <li>© Make Ltd. All rights reserved</li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    )}
  </ThemeContext.Consumer>
);

export default Layout;
