import React, { useEffect, createRef } from "react";

import { cn } from "../lib/helpers";
import ThemeContext from "../context/ThemeContext";
import lottie from "lottie-web";
import animation from "../assets/vca-logo.json";
import * as styles from "./logo.module.css";

const Logo = () => {
  let animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className={cn(styles.root, theme.dark ? styles.dark : styles.light)} ref={animationContainer} />
      )}
    </ThemeContext.Consumer>
  );
};

export default Logo;