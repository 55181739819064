import { Link } from "gatsby";
import React, { useContext } from "react";

import ThemeContext from "../context/ThemeContext";
import ScrollContext, {ScrollProvider} from "../context/ScrollContext";
import Logo from "./logo";
import { cn } from "../lib/helpers";
import * as styles from "./header.module.css";

const Header = ({ siteTitle, showNav, onHideNav, onShowNav }) => {
  const theme = useContext(ThemeContext);
  return (
    <ScrollProvider>
      <ScrollContext.Consumer>
        {scroll => (
          <div className={cn(styles.root, theme.dark ? styles.dark : styles.light, scroll.scrollDir === 'scrolling down' ? styles.scrollingDown : '')}>
            <div className={styles.wrapper}>
              <div className={styles.branding}>
                <Link to="/">
                  <span hidden>{siteTitle}</span>
                  <Logo />
                </Link>
              </div>

              <button className={styles.mobileMenuButton} onClick={showNav ? onHideNav : onShowNav}>
                Menu
              </button>
        
              <nav className={cn(styles.nav, showNav && styles.showNav)}>
                <ul>
                  <li>
                    <Link 
                      to="/exhibitions" 
                      className={styles.navLink} 
                      activeClassName={styles.isActive}
                      partiallyActive={true}
                    >
                      <span>Exhibitions</span>
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/news" 
                      className={styles.navLink} 
                      activeClassName={styles.isActive}
                      partiallyActive={true}
                    >
                      <span>News</span>
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/about" 
                      className={styles.navLink} 
                      activeClassName={styles.isActive}
                    >
                      <span>About</span>
                    </Link>
                  </li>
                </ul>
              </nav>
        
              <button className={cn(styles.lightSwitch, theme.dark ? styles.dark : styles.light)} onClick={theme.toggleDark}>
                <div></div>
                {theme.dark ? <span>Dark</span> : <span>Light</span>}
              </button>
            </div>
          </div>
        )}
      </ScrollContext.Consumer>
    </ScrollProvider>
  )
};

export default Header;
