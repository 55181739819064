import React from "react";
import addToMailchimp from 'gatsby-plugin-mailchimp';

import * as styles from './subscribe-form.module.css';

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      listFields: {
        FNAME: '',
        LNAME: '',
        MMERGE5: ''
      },
      response: null
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { email, listFields } = this.state;

    addToMailchimp(email, listFields)
      .then(response => {
        if (response.result === 'error' && response.msg.includes('already subscribed')) {
          response.msg = 'You’re already subscribed!';
        }
        
        this.setState({ response })
      });
  }

  handleChange = e => {
    if (e.target.type === 'email') {
      this.setState({
        email: e.target.value
      });
    } else {
      this.setState(state => ({
        listFields: {
          ...state.listFields, 
          [e.target.name]: e.target.value
        }
      }));
    }
  }

  render() {
    const { response } = this.state;
    return (
      <>
        <form className={styles.root} autoComplete="#" onSubmit={(e) => this.handleSubmit(e)}>
          <input type="text" name="FNAME" placeholder="First name" autoComplete="#" onChange={(e) => this.handleChange(e)} />
          <input type="text" name="LNAME" placeholder="Last name" autoComplete="#" onChange={(e) => this.handleChange(e)} />
          <input type="email" placeholder="Email" autoComplete="#" onChange={(e) => this.handleChange(e)} />
          <input type="text" name="MMERGE5" placeholder="Company/Organisation (optional)" autoComplete="#" onChange={(e) => this.handleChange(e)} />

          <button type="submit" className={styles.button}>Sign up</button>
        </form>
        {response && (
          <div className={styles.response}>
            <p className={response.result === 'error' ? styles.error : styles.success}>{response.msg}</p>
          </div>
        )}
      </>
    )
  }
}

export default SubscribeForm;